import request from "./services/request";

// 验证邮箱是否存在
export const checkEmailExist = async (email) => {
  return await request.get(`/user/exist/${email}`);
};

// 发送校验码
export const sendCode = async (email) => {
  return await request.get(`/user/sendEmailCode/${email}`);
};

// 注册
export const signIn = async (data, options) => {
  return await request.post("/user/register", data, options);
};

// 校验校验码
export const checkCodeValid = async (data) => {
  return await request.post("/user/validateCode", data);
};

// 登录
export const loginIn = async (data) => {
  return await request.post("/user/login", data);
};

// 重置密码
export const resetPwd = async (data) => {
  return await request.post("/user/changePassword", data);
};

// 退出登录
export const loginOut = async (token) => {
  return await request.get("/user/logout", {}, { headers: { token } });
};

// 获取用户信息

export const getUserInfo = async (token) => {
  return await request.get("/user/detail", {}, { headers: { token } });
};
