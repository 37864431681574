import { stringifyUrl } from "../utils";

const createOptions = (options) => {
  const headers =
    typeof options.headers !== "object" || !options.headers
      ? {}
      : options.headers;
  const finalHeader = {
    ...options,
    headers: {
      "Content-Type": "application/json",
      appId: "6fd0866dffd24341c680ed4a5417bdc2a",
      appChannel: "google",
      "device-type": 3,
      ...headers,
    },
  };

  // 自动注入token
  try {
    if (!finalHeader.headers["token"]) {
      const token = localStorage.getItem("token");
      if (token) {
        finalHeader.headers["token"] = token;
      }
    }
  } catch (error) {}

  // const token2 = localStorage.getItem('token')
  return finalHeader;
};

const fetchWithErrorHandle = async (url, options = {}) => {
  return fetch("https://app.ymedium.top" + url, createOptions(options))
    .then(async (response) => {
      if (!response.ok) {
        throw { code: response.status, msg: "网络请求错误" };
      }
      const data = await response.json();

      if (data.code) {
        throw data;
      }
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// string结构请求
const stringStructFetch = ({ method, url, params, options }) => {
  const mergeOptions = {
    method,
    ...options,
  };
  return fetchWithErrorHandle(url + stringifyUrl(params), mergeOptions);
};
// get请求
const get = async (url, params = {}, options = {}) => {
  return stringStructFetch({ method: "GET", url, params, options });
};
// delete请求
const deleteHandle = async (url, params = {}, options = {}) => {
  return stringStructFetch({ method: "DELETE", url, params, options });
};

// json结构请求
const jsonStructFetch = ({ method, url, data, options }) => {
  const mergeOptions = {
    method,
    body: JSON.stringify(data),
    ...options,
  };
  return fetchWithErrorHandle(url, mergeOptions);
};
// post请求
const post = async (url, data = {}, options = {}) => {
  return jsonStructFetch({ method: "POST", url, data, options });
};

// put请求
const put = async (url, data = {}, options = {}) => {
  return jsonStructFetch({ method: "PUT", url, data, options });
};

const request = {
  get,
  post,
  delete: deleteHandle,
  put,
};

export default request;
