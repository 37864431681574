import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useRoutes } from "react-router";

import Home from "./Home.js";

function App() {
  const [inviter, setInviter] = useState([]);

  function getInviter() {
    var reg = new RegExp("(^|&)invite_id=([^&]*)(&|$)", "i");
    var r = window.location.search.slice(1).match(reg);

    if (r != null) {
      const id = atob(decodeURIComponent(r[2]));

      setInviter(id);
    }
  }

  useEffect(() => {
    getInviter();
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" extra element={<Home inviter={inviter} />}></Route>
      </Routes>
    </>
  );
}

export default App;
